import * as Keys from "./keys";

let en: Record<string, string> = {};

en[Keys.AccountSecurity] = "Account Security";
en[Keys.Account] = "Account";
en[Keys.AccuracyRatio] = "Accuracy Ratio";
en[Keys.Accuracy] = "Accuracy";
en[Keys.ActivationMailSent] = "Activation mail has been sent";
en[Keys.ActiveApiKeyListed] = "Active Api Key Listed";
en[Keys.AddClass] = "Add a Class";
en[Keys.AddFileToUseLiveInterface] = "Add a file to use live interface";
en[Keys.AddLogo] = "Add Logo";
en[Keys.AddToSavedAddress] = "Add To Saved Address";
en[Keys.AddressIsSaved] = "Address is saved";
en[Keys.Address] = "Address";
en[Keys.AiDepartmentApiKeyDeleteConfirmMessage] = "Are you sure to delete the Key AI Department Api Key. If you deleted this Api key users using this key will no longer be able to work with this Api key";
en[Keys.AiDepartmentApiKeyRegenerateConfirmMessage] = "Are you sure to regenerate the Key AI Department Api Key";
en[Keys.AiDepartmentApiKeyRegenerateWarningMessage] = "If you regenerate this Api key users using this key will no longer be able to work with this Api key and they'll need to work with the renewed key";
en[Keys.AiDepartmentApiKey] = "AI Department Api Key";
en[Keys.AllKeys] = "All Keys";
en[Keys.AllServicesAllowed] = "All services allowed";
en[Keys.AllYouNeedIsAKey] = "All you need is a key";
en[Keys.All] = "All";
en[Keys.AllowedServices] = "Allowed Services";
en[Keys.AlreadyAMember] = "Already a member?";
en[Keys.Amount] = "Amount";
en[Keys.AngleCalculateDescription] = "Helps users determine angles between lines or objects, facilitating geometric calculations and design tasks.";
en[Keys.AngleCalculate] = "Angle Calculate";
en[Keys.AngleRotateDescription] = "Enables users to rotate objects or images by a specified angle, providing flexibility in design and presentation.";
en[Keys.AngleRotate] = "Angle Rotate";
en[Keys.ApiKeyCreated] = "Api Key Created";
en[Keys.ApiKeyListed] = "Api Key Listed";
en[Keys.ApiKeyName] = "Api Key Name";
en[Keys.ApiKey] = "Api Key";
en[Keys.ApiKeys] = "Api Keys";
en[Keys.ApiReferenceDescription] = "How to use your Api in your environment";
en[Keys.ApiReference] = "Api Reference";
en[Keys.ApiResponse] = "Api Response";
en[Keys.ApiServicesDocumentDescription] = "Document function is about document related processes.Merge and Split or manipulate pdf pages using this api set.";
en[Keys.ApiServicesExtractDescription] = "Extraction is a process of using AI and machine learning algorithms to identify and extract specific data from a document, image or other input source.";
en[Keys.ApiServicesMetadataDescription] = "The Metadata Extraction and Removal Api is a versatile tool designed to efficiently extract and eliminate metadata from both image and Pdf files.";
en[Keys.ApiServicesOCRDescription] = "OCR software analyzes the image of the text and recognizes each character, then converts them into machine-encoded text that can be edited, searched, and processed by computers.";
en[Keys.ApiServicesRotationDescription] = "Rotation correction can be done manually or automatically using algorithms that detect the angle of rotation needed to align the document.";
en[Keys.ApiServicesSignatureDescription] = "The Signature Processing Api is a robust solution that leverages deep learning and AI technologies to perform various tasks related to signatures.";
en[Keys.ApiServices] = "Api Services";
en[Keys.Api] = "Api";
en[Keys.ApproveFile] = "Approve File";
en[Keys.ApproveWithExpressConsent] = "I approve with my express consent.";
en[Keys.AreYouSure] = "Are you sure";
en[Keys.ArkApiTermsMessage] = "I agree to the <u>Ark Api Terms</u> and accept the <u>Automatic Renewal Terms</u>.";
en[Keys.ArkApiTermsErrorMessage] = "You must agree to the Ark Api Terms and accept the Automatic Renewal Terms.";
en[Keys.AuthenticationAddApiKey] = "Add Api key to your header";
en[Keys.Authentication] = "Authentication";
en[Keys.Back] = "Back";
en[Keys.BaseModel] = "Base Model";
en[Keys.BaselineAccuracy] = "Baseline Accuracy";
en[Keys.BestAccuracy] = "Best Accuracy";
en[Keys.BillingDetails] = "Billing Details";
en[Keys.Billing] = "Billing";
en[Keys.BoxesCalculateDescription] = "Assists users in calculating the dimensions and positions of boxes or containers.";
en[Keys.BoxesCalculate] = "Boxes Calculate";
en[Keys.BoxesDrawDescription] = "Allows users to visually create and customize boxes or containers, aiding in graphical representation.";
en[Keys.BoxesDraw] = "Boxes Draw";
en[Keys.BuiltPlan] = "Built Plan";
en[Keys.BuyNow] = "Buy Now";
en[Keys.Csv] = ".CSV";
en[Keys.Cancel] = "Cancel";
en[Keys.CardDeclinedDueToTransactionProcessing] = "Card declined due to issues with transaction processing";
en[Keys.CardNumber] = "Card Number";
en[Keys.CardholderName] = "Cardholder Name";
en[Keys.ChangeEmail] = "Change Email";
en[Keys.ChangeMyPassword] = "Change my password";
en[Keys.ChangePassword] = "Change Password";
en[Keys.CheckInternetConnection] = "Please check your internet connection.";
en[Keys.Checkout] = "Checkout";
en[Keys.City] = "City";
en[Keys.ClassificationDescription] = "Assign different classes or categories to documents  helping with storage, management";
en[Keys.Classification] = "Classification";
en[Keys.CleanImageDescription] = "Assists users in calculating the dimensions and positions of boxes or containers.";
en[Keys.CleanImage] = "Clean Image";
en[Keys.CleanPdfDescription] = "Allows users to visually create and customize boxes or containers, aiding in graphical representation.";
en[Keys.CleanPdf] = "Clean Pdf";
en[Keys.ClearFilter] = "Clear Filter";
en[Keys.Clear] = "Clear";
en[Keys.ClickBelowToTrainNewModel] = "Click below to train a new model";
en[Keys.ClickBelowTrainNewModel] = "Click below to train a new model";
en[Keys.ClientId] = "Client ID";
en[Keys.CompanyName] = "Company Name";
en[Keys.CompareDescription] = "Helps users compare two or more documents or images, highlighting differences and similarities.";
en[Keys.ComparePlans] = "Compare Plans";
en[Keys.Compare] = "Compare";
en[Keys.ConfidenceLevelTooltipMessage1] = "The extractions level of confidence is indicated here.";
en[Keys.ConfidenceLevelTooltipMessage2] = "The field needs a person to verify the value if the icon is yellow or red.";
en[Keys.ConfidenceLevel] = "Confidence Level";
en[Keys.ConfirmFile] = "Confirm File";
en[Keys.ConfirmPassword] = "Confirm Password";
en[Keys.ConfirmPayment] = "Confirm Payment";
en[Keys.Confirm] = "Confirm";
en[Keys.ContactUs] = "Contact us";
en[Keys.ContactWithUs] = "Contact with us";
en[Keys.Continue] = "Continue";
en[Keys.ContractApproval] = "Contract Approval";
en[Keys.ContractKvkkApprovalMessage] = "* In order to log in to the system, you must approve the Clarification and Consent Text Regarding the Processing of Personal Data.";
en[Keys.Copied] = "Copied";
en[Keys.CopyApiKey] = "Copy Api Key";
en[Keys.CorporateInvoice] = "Corporate Invoice";
en[Keys.CouldNotLoadCaptchaMessage] = "Captcha could not load. Please refresh the page.";
en[Keys.CountDocument] = "{count} Document";
en[Keys.CountPages] = " {count} pages";
en[Keys.CountServices] = "{count} Services";
en[Keys.Country] = "Country";
en[Keys.CreateApiKey] = "Create Api Key";
en[Keys.CreateDate] = "Create Date";
en[Keys.CreateNewKey] = "Create a New Key";
en[Keys.CreateNewModel] = "Create a New Model";
en[Keys.CreateNewPasswordMessage] = "Create a new password with at least 8 characters long.";
en[Keys.CreateNewPassword] = "Create new password";
en[Keys.CreateOne] = "Create One";
en[Keys.CreateYourAccount] = "Create Your Account";
en[Keys.CreateYourFirstKey] = "Create Your First Key";
en[Keys.Create] = "Create";
en[Keys.CreditSpendingsPerApiKey] = "Credit Spendings Per Api Key";
en[Keys.CreditSpendingsPerService] = "Credit Spendings Per Service";
en[Keys.CreditSpendingsWithName] = "Credit Spendings of {name}";
en[Keys.CreditSpendings] = "Credit Spendings";
en[Keys.CreditSystem] = "Credit System";
en[Keys.CreditUsagePerPage] = "Credit usage per page";
en[Keys.CreditUsage] = "Credit Usage";
en[Keys.CreditsAndBillingPage] = "Credits & Billing Page";
en[Keys.Credits] = "Credits";
en[Keys.CropDescription] = "Allows users to trim or remove unwanted areas from images or documents, optimizing visual content.";
en[Keys.Crop] = "Crop";
en[Keys.Curl] = "Curl";
en[Keys.CurrencyType] = "Currency Type";
en[Keys.CurrentBillingCycle] = "Current Billing Cycle";
en[Keys.CurrentPasswordPlaceholder] = "Enter current password";
en[Keys.CurrentPassword] = "Current Password";
en[Keys.Daily] = "Daily";
en[Keys.DateRange] = "Date Range";
en[Keys.Days] = "Days";
en[Keys.DeleteAll] = "Delete All";
en[Keys.DeleteApiKey] = "Delete Api Key";
en[Keys.DeleteChangesConfirmMessage] = "Are you sure to delete your changes?";
en[Keys.DeleteKey] = "Delete Key";
en[Keys.DeleteLogo] = "Delete logo";
en[Keys.Delete] = "Delete";
en[Keys.Description] = "Description";
en[Keys.DoYouHaveMoreQuestions] = "Do you have more questions?";
en[Keys.DocumentTrainDescription] = "Document Train Description";
en[Keys.DocumentTrain] = "Document Train";
en[Keys.Document] = "Document";
en[Keys.DocumentationFunctionsOfArkApiDocumentP1] = "This function is dedicated to handling document-related processes with precision and efficiency. By utilizing this Api set, users have the capability to merge and split Pdf pages according to their specific needs. For instance, you can effortlessly combine multiple Pdf documents into a single file, making it easier to manage and share. Contrarily, if you have a large Pdf document that needs to be divided into smaller, more manageable sections, this function allows you to do so seamlessly. Beyond merging and splitting, the Api set also provides robust tools for manipulating Pdf pages in various ways, ensuring that your document management tasks are streamlined and efficient. Whether for business, educational, or personal use, this function enhances your ability to organize and customize your Pdf documents to meet your exact requirements.";
en[Keys.DocumentationFunctionsOfArkApiExtractListHeader] = "Invoice:";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem10] = "discount amount";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem11] = "total amount";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem1] = "time";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem2] = "date";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem3] = "payment method";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem4] = "tax ration";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem5] = "tax amount";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem6] = "tax office";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem7] = "merchant name";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem8] = "invoice number";
en[Keys.DocumentationFunctionsOfArkApiExtractListItem9] = "discount ratio";
en[Keys.DocumentationFunctionsOfArkApiExtractP1] = "Extraction of labelled information or image, or a part from the input refers to the process of using artificial intelligence (AI) and machine learning algorithms to identify and extract specific data from a document, image or other input source.";
en[Keys.DocumentationFunctionsOfArkApiExtractP2] = "This process involves labeling the areas of interest within the input, such as certain words or fields within a form, and extract these specific labels with a high degree of accuracy. This enables the AI system to extract relevant information quickly and accurately from large amounts of data, saving time and improving efficiency in tasks such as data entry, document processing, and information retrieval.";
en[Keys.DocumentationFunctionsOfArkApiExtractP3] = "User can benefit either our pre-defined models or tailor made models. Currently, we have two pre-defined models and the entities they possess, which are the keys they will output, are as follows:";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem1Content] = "The Api is equipped to intelligently extract a variety of metadata from images and Pdf files. This includes information such as author details, creation dates, geolocation, camera specifications (for images), and document properties (for Pdfs).";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem1Label] = "Metadata Extraction:";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem2Content] = "The Api is not limited to specific file formats, allowing seamless handling of both image files (common formats like JPEG, PNG, etc.) and Pdf files. This adaptability ensures compatibility with a diverse range of documents.";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem2Label] = "Format-Agnostic:";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem3Content] = "In addition to extraction, the Api is designed to remove metadata from the processed files. This includes sensitive information, comments, annotations, and any other metadata that users may want to exclude for privacy or security reasons.";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem3Label] = "Metadata Removal:";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem4Content] = "The Api ensures that sensitive metadata information is securely removed, addressing privacy concerns and complying with data protection regulations.";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem4Label] = "Secure Handling of Sensitive Information:";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem5Content] = ":Cleaned files are provided in standardized output formats, facilitating easy integration with various applications and systems while ensuring that no sensitive metadata is retained.";
en[Keys.DocumentationFunctionsOfArkApiMetadataListItem5Label] = "Output Formats:";
en[Keys.DocumentationFunctionsOfArkApiMetadataP1] = "The Metadata Extraction and Removal Api is a versatile tool designed to efficiently extract and eliminate metadata from both image and Pdf files. This Api combines extraction capabilities with a focus on privacy and security through metadata removal:";
en[Keys.DocumentationFunctionsOfArkApiOcrP1] = "OCR stands for Optical Character Recognition. It is a technology that enables the conversion of scanned or photographed images of text into editable and searchable digital text. OCR software analyzes the image of the text and recognizes each character, then converts them into machine-encoded text that can be edited, searched, and processed by computers. OCR is commonly used to digitize printed documents, such as books, magazines, and newspapers, as well as to extract data from scanned business cards, receipts, invoices, and other types of documents. Handwritten OCR will be implemented soon.";
en[Keys.DocumentationFunctionsOfArkApiOcr] = "OCR (Optical Character Recognition)";
en[Keys.DocumentationFunctionsOfArkApiRotationP1] = "Before performing OCR on a scanned document or extraction processes in general, it is important to ensure that the document is correctly oriented. This can be done through our Rotation service.";
en[Keys.DocumentationFunctionsOfArkApiRotationP2] = "Rotation correction can be done manually or automatically using algorithms that detect the angle of rotation needed to align the document. Automatic rotation correction is especially useful when dealing with large volumes of documents that may have been scanned at different orientations.";
en[Keys.DocumentationFunctionsOfArkApiRotationP3] = "In short: Detect the text in images. Find the angle and make the adjustments according to angle in the image such as rotating.";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem1Content] = "The Api can accurately detect signatures within a given document or image, utilizing advanced pattern recognition and neural network models. It can identify and locate signatures even in complex or cluttered backgrounds, ensuring reliable detection.";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem1Label] = "Signature Detection:";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem2Content] = "Once a signature is detected, the Api can automatically crop the signature area, providing a focused and isolated representation of the signature for further analysis or display.";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem2Label] = "Signature Cropping:";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem3Content] = "The Api employs sophisticated algorithms to compare two signatures and assess their similarity or dissimilarity.";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem3Label] = "Signature Comparison:";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem4] = "It provides a confidence score or similarity metric, aiding in the verification and validation of signatures for authentication purposes.";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem5Content] = "The Api is powered by state-of-the-art deep learning models trained on diverse signature datasets to ensure high accuracy and adaptability to various signature styles and variations.";
en[Keys.DocumentationFunctionsOfArkApiSignatureListItem5Label] = "Deep Learning Models:";
en[Keys.DocumentationFunctionsOfArkApiSignatureP1] = "The Signature Processing Api is a robust solution that leverages deep learning and AI technologies to perform various tasks related to signatures. This versatile Api offers the following key functionalities:";
en[Keys.DocumentationFunctionsOfArkApiSignatureP2] = "With these capabilities, the Signature Processing Api provides a comprehensive solution for organizations and developers seeking to integrate advanced signature-related functionalities into their applications, document management systems, or authentication processes.";
en[Keys.DocumentationFunctionsOfArkApi] = "Functions of ArkApi";
en[Keys.DocumentationGuideDescription] = "You can learn details about ArkApi and find answers for your questions.";
en[Keys.DocumentationHowDoesCreditSystemWorkP1] = "The credit system offered by ArkApi is a simple payment method specifically designed for the platform's functionalities. Credit system  is calculated based on the number of pages made to the Api. After purchasing credits, the corresponding amount of credits will be automatically deducted from your account for each Api request made.";
en[Keys.DocumentationHowDoesCreditSystemWorkP2] = "Visit our purchase page to get more information and review our packages.";
en[Keys.DocumentationHowDoesCreditSystemWork] = "How does Credit System Work?";
en[Keys.DocumentationWhatDoesArkApiOfferP1] = "ArkApi is a powerful Api tool that provides developers and end-users with a wide range of functionalities to simplify their workflows. With ArkApi, developers can easily integrate OCR and data extraction capabilities into their applications, enabling them to extract data from a variety of input types, including Pdf, images, and text. In addition to OCR and data extraction, ArkApi also offers document rotation, splitting, and merging capabilities, making it a comprehensive solution for managing documents of all types. Whether you're working with large, complex documents or small, simple ones, ArkApi's powerful AI and machine learning algorithms ensure that you can easily manipulate and manage them to suit your needs.";
en[Keys.DocumentationWhatDoesArkApiOfferP2] = "By offering these functionalities, ArkApi eliminates the need for manual document processing, reducing errors and increasing efficiency. This allows developers and end-users to focus on more important tasks, such as developing new features or analyzing data, without worrying about time-consuming and error-prone document management tasks.";
en[Keys.DocumentationWhatDoesArkApiOfferP3] = "With ArkApi, developers and end-users can enjoy a streamlined workflow, improved accuracy, and increased productivity, all powered by the latest AI and machine learning technologies. Whether you're a seasoned developer or just starting out, ArkApi is the perfect solution for simplifying your document management tasks and improving your workflow.";
en[Keys.DocumentationWhatDoesArkApiOffer] = "What Does ArkApi Offer?";
en[Keys.DocumentationWhatIsArkApiForListHeader] = "ArkApi is especially useful for those who:";
en[Keys.DocumentationWhatIsArkApiForListItem1] = "Need to retrieve and analyze large amounts of data quickly and accurately.";
en[Keys.DocumentationWhatIsArkApiForListItem2] = "Want to build custom integrations that interact with different data sources.";
en[Keys.DocumentationWhatIsArkApiForListItem3] = "Are developing data-driven applications that require real-time access to data.";
en[Keys.DocumentationWhatIsArkApiForListItem4] = "Need to extract high-quality data.";
en[Keys.DocumentationWhatIsArkApiForP1] = "ArkApi is a powerful tool designed to simplify application development by providing a suite of Apis for managing and analyzing data. Whether you're building web or mobile applications, conducting data analysis, or creating custom integrations, ArkApi is the perfect solution for developers who want to streamline their workflows and increase efficiency.";
en[Keys.DocumentationWhatIsArkApiForP2] = "With ArkApi, developers can eliminate the need for manual data entry and reduce errors during data extraction. This frees up time and resources to focus on more essential tasks, such as developing new features and improving user experience. Whether you're a seasoned developer or just getting started, ArkApi is the perfect solution for managing and analyzing data efficiently and effectively.";
en[Keys.DocumentationWhatIsArkApiFor] = "What is ArkApi for?";
en[Keys.DocumentationWhatIsArkApiP1] = "Introducing ArkApi, a cutting-edge OCR software and Api platform designed to streamline document processing and enhance automation workflows. Leveraging the power of computer vision and machine learning, ArkApi provides developers with a standardized document processing layer that can recognize and extract key information with ease. Our pre-trained data models for common documents, such as invoices, receipts, and passports, make it simple for developers to integrate with our platform.";
en[Keys.DocumentationWhatIsArkApiP2] = "At ArkApi, our mission is to provide our customers with real-time, human-level accuracy in data extraction from both paper and digital documents. With our Api-first approach, we aim to empower developers to create innovative solutions and unlock new business opportunities by harnessing the power of data recognition technology. Join the growing community of businesses already benefiting from the automation and efficiency provided by ArkApi’s Api platform.";
en[Keys.DocumentationWhatIsArkApi] = "What is ArkApi";
en[Keys.Documentation] = "Documentation";
en[Keys.DocumentsUsed] = "Documents used";
en[Keys.Done] = "Done";
en[Keys.DontHaveAnAccount] = "Don't have an account?";
en[Keys.DotNetCSharp] = ".Net(C#)";
en[Keys.DragAndDropFilesHere] = "Drag and drop files here";
en[Keys.Or] = "or";
en[Keys.Supported] = "Supported";
en[Keys.ClickHereToUpload] = "Click Here to Upload";
en[Keys.FileSizeMax] = "File size should be maximum";
en[Keys.EArchive] = "E-Archive";
en[Keys.EInvoice] = "E-Invoice";
en[Keys.Edit] = "Edit";
en[Keys.EmailAddress] = "Email Address";
en[Keys.EmailConfirmedSuccessfully] = "Email confirmed successfully";
en[Keys.Email] = "E-mail";
en[Keys.EnFlag] = "English";
en[Keys.EnLang] = "EN";
en[Keys.En] = "EN";
en[Keys.EnterYourEmailAddress] = "Enter your e-mail address";
en[Keys.EnumActive] = "Active";
en[Keys.EnumClassification] = "Classification";
en[Keys.EnumCreated] = "Created";
en[Keys.EnumCurrentBillingCycle] = "Current Invoice Period";
en[Keys.EnumDaily] = "Daily";
en[Keys.EnumDateRange] = "Date Range";
en[Keys.EnumDate] = "Date";
en[Keys.EnumDocumentPdfCompress] = "Pdf Compress";
en[Keys.EnumDocumentPdfDecrypt] = "Pdf Decrypt";
en[Keys.EnumDocumentPdfEncrypt] = "Pdf Encrypt";
en[Keys.EnumDocumentPdfExtractImage] = "Pdf Extract Image";
en[Keys.EnumDocumentPdfMerge] = "Pdf Merge";
en[Keys.EnumDocumentPdfSplit] = "Pdf Split";
en[Keys.EnumDocument] = "Document";
en[Keys.EnumExpired] = "Expired";
en[Keys.EnumExtractImage] = "Image";
en[Keys.EnumExtractPdf] = "Pdf";
en[Keys.EnumExtractText] = "Text";
en[Keys.EnumExtractTiff] = "Tiff";
en[Keys.EnumExtract] = "Extract";
en[Keys.EnumHigh] = "High";
en[Keys.EnumIdentity] = "Identity";
en[Keys.EnumInvoice] = "Invoice";
en[Keys.EnumLastBillingCycle] = "Last Invoice Period";
en[Keys.EnumLow] = "Low";
en[Keys.EnumMetadataCleanImage] = "Clean Image";
en[Keys.EnumMetadataCleanPdf] = "Clean Pdf";
en[Keys.EnumMetadataExtractImage] = "Extract Image";
en[Keys.EnumMetadataExtractPdf] = "Extract Pdf";
en[Keys.EnumMetadata] = "Metadata";
en[Keys.EnumModelInProgress] = "In Progress";
en[Keys.EnumMonthly] = "Monthly";
en[Keys.EnumNer] = "Named Entity Recognition (NER)";
en[Keys.EnumNumeric] = "Numeric";
en[Keys.EnumOcrImage] = "Image";
en[Keys.EnumOcrPdf] = "Pdf";
en[Keys.EnumOcrTiff] = "Tiff";
en[Keys.EnumOcr] = "Ocr";
en[Keys.EnumReadyForUse] = "Ready";
en[Keys.EnumRevoked] = "Revoked";
en[Keys.EnumRisky] = "Risky";
en[Keys.EnumRotationAngleCalculate] = "Angle Calculate";
en[Keys.EnumRotationAngleRotate] = "Angle Rotate";
en[Keys.EnumRotationBoxesCalculate] = "Boxes Calculate";
en[Keys.EnumRotationBoxesDraw] = "Boxes Draw";
en[Keys.EnumRotation] = "Rotation";
en[Keys.EnumSignatureCompare] = "Compare";
en[Keys.EnumSignatureCrop] = "Crop";
en[Keys.EnumSignatureExtractCoordinate] = "Extract Coordinate";
en[Keys.EnumSignature] = "Signature";
en[Keys.EnumText] = "Text";
en[Keys.EnumTime] = "Time";
en[Keys.EnumTrainFromScratch] = "Train From Scratch";
en[Keys.EnumTraining] = "Training";
en[Keys.EnumWeekly] = "Weekly";
en[Keys.ExpiryDate] = "Expiry Date";
en[Keys.ExploreOurApiServices] = "Explore our Api Services";
en[Keys.Export] = "Export";
en[Keys.ExtractCoordinateDescription] = "Enables users to extract and retrieve coordinate data from documents or images.";
en[Keys.ExtractCoordinate] = "Extract Coordinate";
en[Keys.ExtractImageDescription] = "Helps users determine angles between lines or objects, facilitating geometric calculations and design tasks.";
en[Keys.ExtractImageInfo] = "Extract Image Info";
en[Keys.ExtractImage] = "Extract Image";
en[Keys.ExtractPdfDescription] = "Enables users to rotate objects or images by a specified angle, providing flexibility in design and presentation.";
en[Keys.ExtractPdfInfo] = "Extract Pdf Info";
en[Keys.ExtractPdf] = "Extract Pdf";
en[Keys.ExtractTextInfo] = "Extract Text Info";
en[Keys.ExtractTiffInfo] = "Extract Tiff Info";
en[Keys.Extract] = "Extract";
en[Keys.ExtractedInformation] = "Extracted Information";
en[Keys.FieldMax] = "{fieldname} must be max {max} characters.";
en[Keys.FieldMin] = "{fieldname} must be min {min} characters.";
en[Keys.FieldNotValid] = " {fieldname} is not valid";
en[Keys.FieldRequired] = "Please fill out field";
en[Keys.FileTypes] = "File types";
en[Keys.Filters] = "Filters";
en[Keys.ForgotPassword] = "Forgot password?";
en[Keys.FormDataFormFile] = "The formFile can be either a file object";
en[Keys.FormData] = "Form data";
en[Keys.FormDesc2] = " Create one.";
en[Keys.FormDesc] = "Don’t have an account yet?";
en[Keys.FreePage] = "Free page";
en[Keys.GeneralErrorJson] = "General Error JSON response example";
en[Keys.GetInTouch] = "Get in touch";
en[Keys.GoToHomePage] = "Go to homepage";
en[Keys.GoToPurchasing] = "Go to Purchasing";
en[Keys.GoToSignInPage] = "Go to Sign In Page";
en[Keys.GotConfusedExpertsHereToHelp] = "Got confused? ARKAPI's experts are here to help!";
en[Keys.Guide] = "Guide";
en[Keys.HideApiKey] = "Hide Api Key";
en[Keys.HighAccuracy] = "High Accuracy";
en[Keys.Home] = "Home";
en[Keys.HttpRequest] = "HTTP Request";
en[Keys.IHaveReadAndAccept] = "I’ve read and accept";
en[Keys.IHaveReadAndApprovePreliminaryInformation] = "I have read and approve the Preliminary Information";
en[Keys.IdentityNumber] = "TC Number";
en[Keys.IfYouNeedAnyHelpContactUs] = "If you need any help please contact with us";
en[Keys.ImageInfoDescription] = "Provides detailed information about images, including dimensions, format, color depth, and relevant metadata.";
en[Keys.ImageInfo] = "Image Info";
en[Keys.Inactive] = "Inactive";
en[Keys.Individual] = "Individual";
en[Keys.InsufficientCreditJSON] = "Insufficient Credit JSON response example";
en[Keys.Invalid] = "Invalid";
en[Keys.InvalidEmailAddress] = "Invalid email address";
en[Keys.InvalidPasswordFormat] = "Password must include at least one uppercase letter and one lowercase letter";
en[Keys.Invoice] = "Invoice";
en[Keys.ItFlag] = "Italian";
en[Keys.ItLang] = "IT";
en[Keys.ItsEmptyInHere] = "Ohh... it's empty in here";
en[Keys.JavaScript] = "JavaScript";
en[Keys.JoinTheArkApiNow] = "Join the ARKApi Now";
en[Keys.Json] = ".JSON";
en[Keys.LargeDocumentOcr] = "Large Document Ocr?";
en[Keys.LastBillingCycle] = "Last Billing Cycle";
en[Keys.LeavePage] = "Leave Page";
en[Keys.LimitationsDescription1] = "These limits apply for your current plan.";
en[Keys.LimitationsDescription2] = "If you need to higher them up,";
en[Keys.Limitations] = "Limitations";
en[Keys.LinkSent] = "Link Sent!";
en[Keys.LiveInterface] = "Live Interface";
en[Keys.Login] = "Sign In";
en[Keys.LogoDeleteSuccessfully] = "Logo Delete Successfully";
en[Keys.LogoUploadSuccessfully] = "Logo Upload Successfully";
en[Keys.ManageYourAccount] = "Manage Your Account";
en[Keys.MaxFileSizeWarning] = "Sending unsupported files to your endpoints will result in a 400 error.";
en[Keys.MaxFileSize] = "Maximum file size";
en[Keys.MembershipAgreement] = "Membership Agreement";
en[Keys.MetadataCleanImage] = "Metadata Clean Image";
en[Keys.MetadataCleanPdf] = "Metadata Clean Pdf";
en[Keys.MetadataExtractImage] = "Metadata Extract Image";
en[Keys.MetadataExtractPdf] = "Metadata Extract Pdf";
en[Keys.Metadata] = "Metadata";
en[Keys.ModelCreatedSuccessfully] = "Model created successfully.";
en[Keys.ModelName] = "Model Name";
en[Keys.ModelTrainingUploadErrorMessage] = "Please annotate and confirm at least 20 documents to train your first model.";
en[Keys.ModelTrainingUploadMessage] = "You must annotate at least 20 documents to train your model, and more annotations will significantly improve the accuracy.";
en[Keys.ModelTrainingUploadWarningMessage] = "You must annotate and confirm at least 20 documents to train your first model.";
en[Keys.ModelTraining] = "Model Training";
en[Keys.ModelType] = "Model Type";
en[Keys.ModerateAccuracy] = "Moderate Accuracy";
en[Keys.Month] = "month";
en[Keys.Monthly] = "Monthly";
en[Keys.MoreInformation] = "More Information";
en[Keys.MsExcel] = "MS Excel";
en[Keys.MustBeGreaterThanField] = "Value entered must not be greater than {fieldname}";
en[Keys.MyCurrentPlan] = "My Current Plan";
en[Keys.MyProfile] = "My Profile";
en[Keys.NameSurname] = "Name Surname";
en[Keys.NameYourClass] = "Name Your Class";
en[Keys.NameYourModel] = "Name Your Model";
en[Keys.NewEmailAddress] = "New Email Address";
en[Keys.NewField] = "New {name}";
en[Keys.NewPasswordAgainPlaceholder] = "Enter new password again";
en[Keys.NewPasswordAgain] = "New Password Again";
en[Keys.NewPasswordPlaceholder] = "Enter new password";
en[Keys.NewPassword] = "New Password";
en[Keys.Next] = "Next";
en[Keys.NoApiKeyMeetsCriteria] = "There is no Api key that meets these criteria";
en[Keys.NoCancel] = "No, cancel";
en[Keys.NoDataMessage] = "You didn’t create any {noDataModuleName} yet. Press + to create a new {noDataModuleName}";
en[Keys.NoData] = "No data...";
en[Keys.NoResultDescription] = "How about giving it another try?";
en[Keys.NoResult] = "There is no result...";
en[Keys.NoServiceSelected] = "No service selected";
en[Keys.No] = "No";
en[Keys.NotApproveWithExpressConsent] = "I do not approve with my express consent.";
en[Keys.NumberOfAnnotatedDocuments] = "Number of Annotated Documents";
en[Keys.NumberOfEntities] = "Number of Entities";
en[Keys.NumberOfPage] = "Number of Page";
en[Keys.OcrImageInfo] = "OCR Image Info";
en[Keys.OcrPdfInfo] = "OCR Pdf Info";
en[Keys.OcrTiffInfo] = "OCR Tiff Info";
en[Keys.Ocr] = "Ocr";
en[Keys.OhhItsEmptyInHere] = "Ohh... it's empty in here";
en[Keys.Ok] = "Ok";
en[Keys.OneLastStep] = "One Last Step!";
en[Keys.Operator] = "Operator";
en[Keys.OrganizationKeyCanCustomizeMessage] = "Organization each key can be customized for services";
en[Keys.OurPricing] = "Our Pricing";
en[Keys.PageReadyToCreateYourFirstApiKey] = "This page is ready for you to create your first Api Key";
en[Keys.Page] = "page";
en[Keys.EmailChangedSuccessfully] = "Email changed successfully";
en[Keys.PasswordChangedSuccessfully] = "Password changed successfully";
en[Keys.PasswordFormatMessage] = "Password must include at least one uppercase letter and one lowercase letter";
en[Keys.PasswordResetMailSent] = "Password reset mail has been sent";
en[Keys.PasswordResetSuccessfully] = "Password has been reset successfully";
en[Keys.Password] = "Password";
en[Keys.PasswordsNotMatch] = "Passwords don't match";
en[Keys.PayAsYouGo] = "Pay as You Go";
en[Keys.PaymentDetails] = "Payment Details";
en[Keys.PaymentSuccessful] = "Payment Successful";
en[Keys.Payment] = "Payment";
en[Keys.PdfCompressDescription] = "Reduce the file size of Pdf documents, making them easier to share and store without compromising quality.";
en[Keys.PdfCompress] = "Pdf Compress";
en[Keys.PdfDecryptDescription] = "Remove encryption from Pdf documents, allowing authorized individuals to access.";
en[Keys.PdfDecrypt] = "Pdf Decrypt";
en[Keys.PdfEncryptDescription] = "Secure Pdf documents by adding encryption, ensuring that only authorized individuals.";
en[Keys.PdfEncrypt] = "Pdf Encrypt";
en[Keys.PdfExtractImageDescription] = "Extract images embedded within Pdf documents, providing a way to access and use images separately.";
en[Keys.PdfExtractImage] = "Pdf Extract Image";
en[Keys.PdfInfoDescription] = "Offers essential details about Pdf documents, such as metadata, properties, and page count.";
en[Keys.PdfInfoExtractFeedbackMessage] = "Please provide us feedback approving whether your data matches the results.";
en[Keys.PdfInfoNoResponseMessage] = "Select an Api Key, a modal,an OCR option and use service  to extract informations by type.";
en[Keys.PdfInfoServiceInfoMessage] = "Your credits won’t be spent until you  use the service.";
en[Keys.PdfInfo] = "Pdf Info";
en[Keys.PdfMergeDescription] = "Combine multiple Pdf files into a single document, facilitating streamlined document organization.";
en[Keys.PdfMerge] = "Pdf Merge";
en[Keys.PdfSplitDescription] = "Divide a single Pdf document into multiple separate files,manage specific sections or pages of a document.";
en[Keys.PdfSplit] = "Pdf Split";
en[Keys.PhoneNumber] = "Phone Number";
en[Keys.Plan] = "Plan";
en[Keys.PleaseFieldName] = "Please {field}";
en[Keys.PleaseFillOutExpiryDate] = "Please fill out Expiry Date";
en[Keys.Post] = "Post";
en[Keys.PreliminaryInformationForm] = "Preliminary Information Form";
en[Keys.Processing] = "Processing";
en[Keys.PurchaseMoreCredits] = "Purchase more credits";
en[Keys.Purchasing] = "Purchasing";
en[Keys.Python] = "Python";
en[Keys.QueryParameterDescription] = "Add query parameter to api url";
en[Keys.QueryParameter] = "Query Parameter";
en[Keys.Recommended] = "Recommended";
en[Keys.RecordCancelledSuccessfully] = "Record cancelled successfully.";
en[Keys.RecordDeletedSuccessfully] = "Record deleted successfully.";
en[Keys.RecordSavedSuccessfully] = "Record saved successfully.";
en[Keys.RecordUpdatedSuccessfully] = "Record updated successfully.";
en[Keys.ReenterYourArkApiPassword] = "Reenter your ArkApi password to continue";
en[Keys.RefreshKey] = "Refresh Key";
en[Keys.RegenerateKeyMessageTitle] = "Are you sure to regenerate the Key “{name}”?";
en[Keys.RegenerateKeyMessage] = "If you regenerate this Api key, users using this key will no longer be able to work with this Api key and they’ll need to work with the renewed key.";
en[Keys.RegenerateKey] = "Regenerate Key";
en[Keys.Regenerate] = "Regenerate";
en[Keys.RegisterCompleteMessage] = "Registration has been completed. Please click the link in your most recently received activation e-mail to activate your account";
en[Keys.Remaining] = "Remaining";
en[Keys.RequestBodyDescription] = "Request Body";
en[Keys.RequestBody] = "Body";
en[Keys.Required] = "Please fill out {fieldname}";
en[Keys.ResendEmail] = "Resend Email";
en[Keys.ResetLinkSentToMailAddress] = "A reset link sent to your mail address.";
en[Keys.ResetMyPassword] = "Reset My Password";
en[Keys.ResetPasswordInvalidToken] = "Used code. Please make new password renewal request again or take action via the link in the most recently sent e-mail.";
en[Keys.ResetPassword] = "Reset Password";
en[Keys.ResetYourPassword] = "Reset Your Password";
en[Keys.ResponseSchemeDescription] = "This section describes the extracted features in your Api response.";
en[Keys.ResponseScheme] = "Response Scheme";
en[Keys.ResponseStructure] = "Response structure";
en[Keys.ReturnToSite] = "Return to Site";
en[Keys.RevokeApiKeyMessageTitle] = "Are you sure to revoke the Key “{name}”?";
en[Keys.RevokeApiKeyMessage] = "If you revoke this Api key, users using this key will no longer be able to work with this Api key.";
en[Keys.RevokeApiKey] = "Revoke Api Key";
en[Keys.RotationAngleCalculate] = "Rotation Angle Calculate";
en[Keys.RotationAngleRotate] = "Rotation Angle Rotate";
en[Keys.RotationBoxesCalculate] = "Rotation Boxes Calculate";
en[Keys.RotationBoxesDraw] = "Rotation Boxes Draw";
en[Keys.Rotation] = "Rotation";
en[Keys.SampleCode] = "Sample Code";
en[Keys.SaveAndGoToModelPage] = "Save & Go to Model Page";
en[Keys.SaveAndSubmit] = "Save & Submit";
en[Keys.SaveLogo] = "Save logo";
en[Keys.Save] = "Save";
en[Keys.SavedAddress] = "Saved Address";
en[Keys.SavedAddresses] = "Saved Addresses";
en[Keys.SearchApiKeyActive] = "Search Api Key Active";
en[Keys.SearchApiKey] = "Search Api Key";
en[Keys.Search] = "Search";
en[Keys.SecurityCode] = "Security Code";
en[Keys.SeeSpendingsInDetail] = "See Spendings In Detail";
en[Keys.SelectAddress] = "Select Address";
en[Keys.SelectAll] = "Select All";
en[Keys.SelectAnServiceFromBelowToSeeUsageTime] = "Select a service from below to see its usage by time";
en[Keys.SelectAtLeastOneService] = "You should select at least one service";
en[Keys.SelectModel] = "Select Model";
en[Keys.SelectServiceFromBelowToSeeStatisticsForThatService] = "Select a service from below to see statistics for that service";
en[Keys.SelectYourBaseModel] = "Select Your Base Model";
en[Keys.SelectYourModelType] = "Select Your Model Type";
en[Keys.SelectYourPlan] = "Select Your Plan";
en[Keys.SelectedCount] = "{count} Selected";
en[Keys.SendMail] = "Send mail";
en[Keys.ServiceApiKeyNotCreatedYetMessage] = "You have not created any Api keys yet. To use the services, you must first create an Api key.";
en[Keys.ServiceCategories] = "Service Categories";
en[Keys.ServiceCategories] = "Service Categories";
en[Keys.ServicesAllowed] = "services allowed";
en[Keys.Services] = "{count} Services";
en[Keys.SetupFields] = "Set up Fields";
en[Keys.SetupClasses] = "Set up Classes";
en[Keys.ShortDocumentOcr] = "Short Document Ocr?";
en[Keys.ShowApiKey] = "Show Api Key";
en[Keys.SignInDescription] = "Ark Api";
en[Keys.SignInWithApiKey] = "Sign In With an Api Key";
en[Keys.SignIn] = "Sign In";
en[Keys.SignOut] = "Sign Out";
en[Keys.SignatureCompare] = "Signature Compare";
en[Keys.SignatureCrop] = "Signature Crop";
en[Keys.SignatureExtractCoordinate] = "Signature Extract Coordinate";
en[Keys.Signature] = "Signature";
en[Keys.SomethingWentWrongConfirmation] = "Something went wrong on confirmation";
en[Keys.SortBy] = "Sort by:";
en[Keys.SpendingsByCategories] = "Spendings by Categories";
en[Keys.SpendingsByTime] = "Spendings by Time";
en[Keys.Statistics] = "Statistics";
en[Keys.StatusChangedSuccesfully] = "Status updated successfully";
en[Keys.Status] = "Status";
en[Keys.Submit] = "Submit";
en[Keys.SuccessFulJSON] = "Successful JSON response example";
en[Keys.SupportedDocuments] = "Supported documents";
en[Keys.TaxNumber] = "Tax Number";
en[Keys.TaxOffice] = "Tax Office";
en[Keys.TestYourModel] = "Test Your Model";
en[Keys.TextInfoDescription] = "Offers detailed information about text content, including text-related metadata ";
en[Keys.TextInfo] = "Text Info";
en[Keys.ThisCardNumberIsNotValid] = "This card number is not valid";
en[Keys.TiffInfoDescription] = "Provides detailed information about TIFF, including relevant metadata, aiding in image processing.";
en[Keys.TiffInfo] = "Tiff Info";
en[Keys.ToPurchaseMoreCredits] = "to purchase more credits";
en[Keys.TotalCountPages] = " total ({count} pages)";
en[Keys.TotalSpendings] = "Total Spendings";
en[Keys.Total] = "total";
en[Keys.TrFlag] = "Türkçe";
en[Keys.TrLang] = "TR";
en[Keys.Tr] = "TR";
en[Keys.TrainModel] = "Train Model";
en[Keys.TrainNewModel] = "Train a New Model";
en[Keys.TryAgain] = "Try again";
en[Keys.Undo] = "Undo";
en[Keys.UpdateEmailMessage] = "When you update your email address we'll send you an email asking you to confirm the change";
en[Keys.Update] = "Update";
en[Keys.Upload] = "Upload";
en[Keys.Url] = "URL";
en[Keys.UseItInteractively] = "Use It Interactively";
en[Keys.ModelOcrUseServiceMessage] = "Once you select an Api Key, a modal and an OCR option, you'll be able to use the service.";
en[Keys.UseService] = "Use Service";
en[Keys.VerificationLinkSentMessage] = "We have sent a verification link to new email. Check your email to complete verification process. You might need to check your spam folder";
en[Keys.VerifyYourEmailMessage] = "Please verify your email clicking the link that sent to complete your registration.";
en[Keys.VerifyYourPassword] = "Verify Your Password";
en[Keys.VisitOur] = "Visit our";
en[Keys.WantToMakeChanges] = "Do you want to make the changes?";
en[Keys.Weekly] = "Weekly";
en[Keys.WelcomeToArkapi] = "Welcome to ArkApi";
en[Keys.Xml] = "XML";
en[Keys.YesDelete] = "Yes, delete";
en[Keys.Yes] = "Yes";
en[Keys.YouAnnotateAtLeast20DocumentsTrainNewModel] = "You must annotate at least 20 documents to train a new model";
en[Keys.YouCanCopyKeyMessage] = "You can copy your key and use it for your Api requests";
en[Keys.YouCanCreateClassificationModelMessage] = "You can add a class and documents to that class to create a new classification model type.";
en[Keys.YouCanCreateNewKeyMessage] = "You can create a new key for your account or for your organization. Each key can be customized for services.";
en[Keys.YouCanCreateNewModelMessage] = "You can create a new model for your organization. Each model can be customized.";
en[Keys.YouCanSetNewPassword] = "You can set a new password";
en[Keys.YouHaveNotSpentAnyCredit] = "You haven’t spent any credit yet";
en[Keys.YouHaventFinishedCreditPayment] = "You havent finished credit payment yet. Do you want to leave without finishing?";
en[Keys.YourComment] = "Your Comment";
en[Keys.YourPasswordHasBeenCreated] = "Your password has been created";
en[Keys.YourSelectedPlan] = "Your selected plan";
en[Keys.YourStatisticWillBeReadySoon] = "Your statistic will be ready soon";
en[Keys.DocumentUploaded] = "{count} Document(s) uploaded"
en[Keys.GridView] = "Grid View"
en[Keys.ListView] = "List View"
en[Keys.DeleteTheClass] = "Delete the Class"
en[Keys.DoYouWantToDeleteTheClass] = "Do you want to delete the class?"
en[Keys.IfYouDeleteClass] = "If you delete it, your uploaded files in this classes will also be deleted."
en[Keys.AddClassToStart] = "Add a Class to Start"
en[Keys.YouNeedToCreateAClass] = "You need to create a class first to start uploading documents."
en[Keys.IconAddField] = "+ Add Field";
en[Keys.Copy] = "Copy";
en[Keys.File] = "File";
en[Keys.CountFiles] = "{count} Files";
en[Keys.JsonDownloadText] = "Json";
en[Keys.ZipDownloadText] = "Zip";
en[Keys.SplitIntoPageRanges] = "Split into page ranges of:"
en[Keys.SplitUseServiceMessage] = "Split PDF by custom ranges or fixed ranges into independent PDFs. You can select an API Key, upload document, select range mode and use the service."
en[Keys.FixedRanges] = "Fixed Ranges"
en[Keys.CustomRanges] = "Custom Ranges"
en[Keys.SelectRangeMode] = "Select range mode:"
en[Keys.RangeWithNumber] = "Range {number}:"
en[Keys.FromPage] = "from page:"
en[Keys.To] = "to:"
en[Keys.AddRange] = "Add Range"
en[Keys.FixedRangesSplitInfoMessage] = "This PDF will be split into files of {fixedRange} pages. {pdfCount} will be created."
en[Keys.CustomRangesSplitInfoMessage] = "This PDF will be split into files. {pdfCount} will be created."
en[Keys.OcrType] = "Ocr Type"
en[Keys.NumberOfClasses] = "Number of Classes"
en[Keys.NumberOfTotalDocuments] = "Number of Total Documents"
en[Keys.UploadNewDocuments] = "Upload New Documents"
en[Keys.UploadDocuments] = "Upload Documents"
en[Keys.UploadedDocuments] = "Uploaded Documents"
en[Keys.View] = "View"
en[Keys.Classes] = "Classes"
en[Keys.ClassificationStartInfoMessage] = "Waiting for your first file to start."
en[Keys.ClassificationResponseInfoMessage] = "You can view which classes your documents belong to"
en[Keys.CreditsRemained] = "Credits Remained"
en[Keys.Edited] = "Edited"
en[Keys.CountDocumentsAreDisplayed] = "{count} documents are displayed"
en[Keys.ChangeUploadView] = "Change Upload View"
en[Keys.AddAClassByTyping] = "Add a class by typing here"
en[Keys.TrainDocumentsFromScratch] = "Train documents from scratch or use classes from ready-made base models"
en[Keys.UploadFilesToSelectedClass] = "You can upload files to selected class"
en[Keys.UploadMoreFilesToSelectedClass] = "You can upload more files to selected class"
en[Keys.SaveYourModelStartTraining] = "You can save your model and start training or continue adding classes and files later"
en[Keys.UploadFiles] = "Upload Files"
en[Keys.AddAField] = "Add a Field"
en[Keys.AddAFieldByTyping] = "Add a field by typing here"
en[Keys.ChooseCategory] = "Choose a Category"
en[Keys.ChooseCategoryForField] = "Choose a category for your field"
en[Keys.AnnotateFields] = "Annotate Fields"
en[Keys.AnnotateFieldsOnDocument] = "Annotate fields on the document"
en[Keys.SwitchBetweenDocuments] = "Switch Between Documents"
en[Keys.SwitchBetweenDocumentsHere] = "You can switch between different documents here"
en[Keys.YouCanUploadNewDocuments] = "You can upload new documents"
en[Keys.OnceYouConfirm] = "Once you confirm your file, you can save it and go to the model page or continue annotating other files."
en[Keys.IfYouSaveYourModel] = "If you save your model, you can start training or you can continue to annotate the documents later."
en[Keys.EnterCorrectValue] = "Enter Correct Value"
en[Keys.IncorrectPassword] = "Incorrect Password"
en[Keys.UseServiceMessage] = "You can select an API Key, upload document and use the service."
en[Keys.MergeUseServiceMessage] = "You can select an API Key, upload documents and use the service."
en[Keys.OcrUseServiceMessage] = "Once you select an API Key and upload document , you'll be able to use the service."
en[Keys.ApproveFileSuccessfully] = "Approve file successfully..."
en[Keys.UploadLower] = "upload"
en[Keys.YouCanUploadImage] = "You can upload .jpg, .png, .webp, .tiff, heic or .pdf"
en[Keys.DragAndDropOr] = "Drag and drop or"
en[Keys.ToStart] = "to start"
en[Keys.FilesUploadedSuccessfully] = "Files uploaded successfully."
en[Keys.FileUploadFailed] = "File upload failed."
en[Keys.YourApiResponseWillBeReady] = "Your API Response will be ready for review very soon."
en[Keys.Revoke] = "Revoke"
en[Keys.YourApiKeysWilBeReady] = "Your API Keys will be ready soon."
en[Keys.CopyClientId] = "Copy Client ID"
en[Keys.IHaveReadAndApprove] = "I have read and approve"
en[Keys.YouNeedToAccept] = "You need to accept {form}"
en[Keys.PreliminaryInformation] = "Preliminary Information"
en[Keys.YourModelsWillBeReady] = "Your models will be ready soon."
en[Keys.OrderNo] = "Order Number"
en[Keys.SessionExpired] = "Your session has expired. You will be directed to the login screen. Please wait."
en[Keys.TrainStart] = "Model training has started. Once the training is completed, your model will be transferred to 'Ready to Use' status."
en[Keys.CommonDelete] = "Deletion completed successfully."
en[Keys.CommonSave] = "Record save completed successfully."
en[Keys.CommonUpdate] = "Record update completed successfully."
en[Keys.KeyRegenerate] = "Api Key is regenerated"
en[Keys.KeyRevoke] = "Api Key is revoked"
en[Keys.CommonDeleteButton] = "Delete"
en[Keys.ModelDeleteHeader] = "Delete Model"
en[Keys.ModelDeleteBody] = "Are you sure want to delete model?"
en[Keys.PageNotFound] = "Page not found"
en[Keys.SorryWeCouldntFindThisPage] = "Sorry,we couldn’t find this page. Let’s get you back."
en[Keys.YourSessionHasExpired] = "Your session has expired"
en[Keys.PleaseSignInToContinue] = "Please sign in to continue"
en[Keys.FileZeroByteMessage] = "You cannot upload 0 byte document."
en[Keys.FileExceedMaxCapMessage] = "You cannot upload document larger than {capacity} MB."
en[Keys.FileUploadBlackList] = "Documents with {extensions} extension(s) are not supported."
en[Keys.FileMalwareExistMessage] = "We do not upload the {name} document to the system. Please try again with a secure document."
en[Keys.FileUploadFailedError] = "{name} file can not be uploaded to the system. Please try again."
en[Keys.FilesUploadingPleaseWait] = "Upload in progress, please wait."
en[Keys.YourFilesWillBeReady] = "Your files will be uploaded soon."
en[Keys.EditFields] = "Alanı Düzenle"
en[Keys.SeeDocuments] = "Dokümanları Görüntüle"
en[Keys.DeleteModel] = "Modeli Sil"
en[Keys.ContinueToAnnotate] = "Continue to Annotate"

export default en;